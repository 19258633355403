import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import ProfileContainer from 'components/Sections/Profile/ProfileContainer'
import PageTitle from 'components/UIElements/PageTitle'
import SongsList from 'components/Sections/Songs/SongsList'
import Blockquote from 'components/UIElements/Blockquote'
import PageNav from 'components/UIElements/Navigation/PageNav'
// Models
import { IProfileHeader, IProfileMenuItem } from 'models/Profile'
import { IContextWithPageNav } from 'models/Context'
import { IOptionalMetaProps } from 'models/SEO'
import { IArtist } from 'models/Artist'
import { ISong } from 'models/Song'

type ArtistSongsPageProps = {
    artist: IArtist
    songs: {
        totalCount: number
        nodes: ISong[]
    }
    discography: {
        totalCount: number
    }
}

const ArtistSongsPage: React.FC<
    PageProps<ArtistSongsPageProps, IContextWithPageNav>
> = ({ data, pageContext: context }) => {
    const { currentPage, totalPages } = context
    const artist = data.artist
    const totalSongs = data.songs.totalCount
    const totalDisks = data.discography.totalCount
    const seo: IOptionalMetaProps = {
        title: artist.name,
        description: `Canciones de ${artist.name}`,
        image: artist.avatar.file?.url,
    }

    const { name, avatar, cover, slug } = artist
    const {
        amazonMusic,
        instagram,
        line,
        spotify,
        tiktok,
        twitter,
        web,
        youtube,
        youtubeMusic,
    } = artist
    const subname = artist.alias ? (
        <>
            Otros nombres:{' '}
            {artist.alias.map((a, index) => (
                <small key={a}>
                    {index + 1 < artist.alias!.length ? `${a}, ` : a}
                </small>
            ))}
        </>
    ) : (
        <></>
    )

    const headerProps: IProfileHeader = {
        type: 'Artista',
        name,
        avatar,
        cover,
        subname,
        social: {
            amazonMusic,
            instagram,
            line,
            spotify,
            tiktok,
            twitter,
            web,
            youtube,
            youtubeMusic,
        },
    }

    const menu: IProfileMenuItem[] = [
        { text: `Perfil`, to: `/artists/${slug}` },
        {
            text: `Discografía`,
            to: `/artists/${slug}/discography`,
            count: totalDisks,
        },
        { text: `Canciones`, to: `/artists/${slug}/songs`, count: totalSongs },
    ]

    const songs = data.songs.nodes

    return (
        <Layout seo={seo}>
            <ProfileContainer menu={menu} {...headerProps}>
                <PageTitle title="Canciones" />
                {songs.length > 0 ? (
                    <SongsList songs={songs} />
                ) : (
                    <Blockquote>No hay nada para mostrar...</Blockquote>
                )}
                <PageNav
                    to={`/artists/${artist.slug}/songs`}
                    totalPages={totalPages}
                    currentPage={currentPage}
                />
            </ProfileContainer>
        </Layout>
    )
}

export default ArtistSongsPage

export const pageQuery = graphql`
    query ArtistSongsPage($slug: String!, $limit: Int!, $skip: Int!) {
        artist: contentfulArtists(slug: { eq: $slug }) {
            name
            name_jap
            slug
            birthday
            alias

            avatar {
                gatsbyImageData(width: 200)
                file {
                    url
                }
            }
            cover {
                gatsbyImageData(layout: CONSTRAINED)
            }

            instagram
            youtube
            twitter
            line
            tiktok
            web
            spotify
            amazonMusic
            youtubeMusic
            deezer
        }

        songs: allContentfulSongs(
            limit: $limit
            skip: $skip
            sort: { fields: release_date, order: DESC }
            filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            totalCount
            nodes {
                name
                slug

                release_date
                duration

                thumbnail {
                    gatsbyImageData(width: 50)
                }
            }
        }

        discography: allContentfulDisks(
            filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            totalCount
        }
    }
`
